<template>
  <div id="PaymentModule" class="ReferencePaymentMain">
    <ReferenceNavBar />
    <br />
    <br />
    <br />
    <br />

    <v-card class="ma-auto" elevation="3" pa="6" max-width="40%">
      <div v-if="this.isConfirmLoading">
        <v-card-title>
          Проверка платежа, пожалуйста подождите...
        </v-card-title>
      </div>
      <div v-else-if="this.isConfirmError">
        <v-card-title>
          Не удалось проверить платёж, попробуйте ещё раз позже
        </v-card-title>
      </div>
      <div v-else-if="this.isConfirmComplete">
        <v-card-title> Заказ № {{ this.confirmOrderNumber }} </v-card-title>
        <v-card-text>
          Оплата заказа произведена успешно <br />
          Дата заказа: {{ getOrderDate }} <br />
        </v-card-text>
        <div v-if="confirmResult !== true">
          <v-card-text>
            Оплата заказа не выполнена
          </v-card-text>
        </div>
      </div>
      <v-card-actions class="align-items-center justify-space-around flex-wrap">
        <router-link to="/">Вернуться на главную</router-link>
      </v-card-actions>
    </v-card>
    <div class="footer">
      <ReferenceFooter />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ReferenceNavBar from '@/components/reference/ReferenceNavBar';
import ReferenceFooter from '@/components/reference/ReferenceFooter';

export default {
  components: {
    ReferenceNavBar,
    ReferenceFooter
  },
  computed: {
    getOrderDate() {
      return moment(this.confirmOrderDate).format('DD/MM/YYYY HH:mm');
    },
    ...mapGetters('reference', ['isConfirmLoading']),
    ...mapGetters('reference', ['isConfirmError']),
    ...mapGetters('reference', ['isConfirmComplete']),
    ...mapGetters('reference', ['confirmResult']),
    ...mapGetters('reference', ['confirmMessage']),
    ...mapGetters('reference', ['confirmOrderNumber']),
    ...mapGetters('reference', ['confirmOrderDate']),
    ...mapGetters('reference', ['confirmReferenceName'])
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    ...mapActions('reference', ['confirmPayment'])
  },
  mounted() {
    this.confirmPayment({ token: this.$route.query.token });
  }
};
</script>
<style>
.ReferencePaymentMain {
  font-weight: 400;
  font-family: RussianRail;
  background-color: rgba(255, 255, 255, 0.2);
  height: 100%;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
